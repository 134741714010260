"use client";

import React, { createContext, useContext, useState, useMemo } from "react";
export const BookingContext = createContext({
  setFlow: () => {},
  setBookingData: () => {},
  setBookingId: () => {}
});
const BookingProvider = ({
  children
}) => {
  // The different flows we have:
  // 1. 'homepage' call '/practioners/get_provider_based_on_care_timeframe/'
  // 2. 'directbooking' call '/bookings/provider_direct_booking/' if no provider call '/bookings/booking_request_without_auth/'
  // 3. 'dashboard' call 'verifyStripe, getGeneralBookingDet, bookingRequest'

  const [flow, setFlow] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [isNewUser, setIsNewUser] = useState("null");
  const [planType, setPlanType] = useState("self_pay");
  const [providerEmail, setProviderEmail] = useState(null);
  const [patientCardDetails, setPatientCardDetails] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [patientProfilePhoto, setPatientProfilePhoto] = useState(null);
  const [providerBankAccount, setProviderBankAccount] = useState(null);
  const [newFinalizeBookingDetails, setNewFinalizeBookingDetails] = useState(null);
  const [hasInsurance, setHasInsurance] = useState(null);
  const [providerWithInsurance, setProviderWithInsurance] = useState(null);
  const [patientInPractice, setPatientInPractice] = useState(null);
  const [membershipType, setMembershipType] = useState(null);
  const [practiceId, setPracticeId] = useState(null);
  const values = useMemo(() => ({
    flow,
    setFlow,
    bookingData,
    setBookingData,
    bookingId,
    setBookingId,
    isNewUser,
    setIsNewUser,
    providerEmail,
    setProviderEmail,
    patientCardDetails,
    setPatientCardDetails,
    profilePhoto,
    setProfilePhoto,
    patientProfilePhoto,
    setPatientProfilePhoto,
    providerBankAccount,
    setProviderBankAccount,
    newFinalizeBookingDetails,
    setNewFinalizeBookingDetails,
    patientInPractice,
    setPatientInPractice,
    providerWithInsurance,
    setProviderWithInsurance,
    hasInsurance,
    setHasInsurance,
    membershipType,
    setMembershipType,
    practiceId,
    setPracticeId,
    planType,
    setPlanType
  }), [flow, setFlow, bookingData, setBookingData, bookingId, setBookingId, isNewUser, setIsNewUser, providerEmail, setProviderEmail, patientCardDetails, setPatientCardDetails, profilePhoto, setProfilePhoto, patientProfilePhoto, setPatientProfilePhoto, providerBankAccount, setProviderBankAccount, newFinalizeBookingDetails, setNewFinalizeBookingDetails, patientInPractice, setPatientInPractice, providerWithInsurance, setProviderWithInsurance, hasInsurance, setHasInsurance, membershipType, setMembershipType, practiceId, setPracticeId, planType, setPlanType]);
  return <BookingContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="BookingProvider" data-sentry-source-file="BookingProvider.jsx">{children}</BookingContext.Provider>;
};
const useBooking = () => {
  const context = useContext(BookingContext);
  if (context === undefined) {
    throw Error("useBooking() MUST BE USED WITHIN A <BookingProvider/> !!!");
  }
  return context;
};
export { BookingProvider, useBooking };