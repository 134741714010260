const BigPurPleCard = ({
  toptext,
  title,
  subtitle,
  buttontext,
  bgcolor,
  onclick
}) => {
  return <div className={`flex w-full h-full flex-col items-start justify-center gap-2 text-white ${bgcolor} px-8 sm:px-14 rounded-3xl`} data-sentry-component="BigPurPleCard" data-sentry-source-file="BigPurpleCard.jsx">
      <p className="font-light text-xs">{toptext}</p>
      <h2 className="text-2xl sm:text-3xl font-medium">{title}</h2>
      <p className="font-light sm:text-base text-xs">{subtitle}</p>
      <button onClick={onclick} type="button" className="bg-new-10 text-white rounded-2xl h-12 w-fit px-8 font-light text-sm mt-1">
        {buttontext}
      </button>
    </div>;
};
export default BigPurPleCard;