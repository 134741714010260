"use client";

import { Button, Switch } from "antd";
import ProviderSummaryCard2 from "./ProviderSummaryCard2";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { useBooking } from "../contexts/BookingProvider";
import { AUTH_TOKEN_KEY, baseUrl } from "../resources/resources";
import { setAuthorizationToken } from "../services/api";
import axios from "axios";
import { CaretRightFilled } from "@ant-design/icons";
import Image from "next/image";
const HeroSection2 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);
  const [_loading, setLoading] = useState(true); // loading is unused. Put to use later
  const [providers, setProviders] = useState([]);
  const {
    setFlow,
    setIsNewUser
  } = useBooking();
  const router = useRouter();
  const fetchProviderDetails = async () => {
    try {
      const response = await axios.get(baseUrl + "/practioners/get_all_health_providers/");
      setProviders(response?.data?.data);
    } catch (error) {
      console.error("Error fetching health providers:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleRoute = () => {
    router.push("/provider");
  };
  useEffect(() => {
    fetchProviderDetails();
    localStorage.removeItem("intent");
    localStorage.removeItem(AUTH_TOKEN_KEY);
    setAuthorizationToken(null);
    setFlow(null);
    setIsNewUser("null");
  }, []);
  const showModal = () => {
    // this function now routes to the request care page instead of setIsModalOpen(true).
    router.push("/request");
    setFlow("homepage");
    // setIsModalOpen(true);
  };
  const showPartnerModal = () => {
    setIsPartnerModalOpen(true);
  };
  const handlePartnerOk = () => {
    setIsPartnerModalOpen(false);
  };
  const handlePartnerCancel = () => {
    setIsPartnerModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return <section className="w-screen flex flex-col items-center justify-center gap-5 bg-new2-10" data-sentry-component="HeroSection2" data-sentry-source-file="HeroSection2.jsx">
      {/* hero */}
      <div className="bg-hero-blur bg-bottom bg-no-repeat bg-cover w-full hidden flex-col items-center justify-start pt-40 pb-20 sm:pb-40 md:gap-10 gap-5">
        <div className="relative w-full max-w-[1440px] px-4 md:px-16 lg:px-32 flex flex-col items-center justify-start md:gap-10 gap-5">
          <Image src={"/feverfloat.svg"} alt="a" className="absolute sm:left-[10%] left-2 -top-10 sm:top-[10%]" width={80} height={80} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <Image src={"/sicknessfloat.svg"} alt="a" className="absolute sm:right-[10%] sm:top-[20%] -top-10 right-2" width={80} height={80} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <Image src={"/allergyfloat.svg"} alt="a" className="absolute sm:left-[13%] sm:top-[60%] left-2 top-[50%]" width={100} height={100} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <Image src={"/sorethroatfloat.svg"} alt="a" className="absolute sm:right-[7.5%] sm:top-[55%] right-2 -bottom-10" width={120} height={120} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <Image src={"/headachefloat.svg"} alt="a" className="absolute sm:left-[15%] sm:top-[80%] left-2 -bottom-10" width={200} height={200} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <Image src={"/flufloat.svg"} alt="a" className="absolute hidden sm:flex right-[15%] top-[90%]" width={100} height={100} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <div className="flex items-center gap-2 sm:text-base text-xs">
            <span className="font-medium">For patients</span>
            <Switch onChange={handleRoute} data-sentry-element="Switch" data-sentry-source-file="HeroSection2.jsx" />
            <span>For providers</span>
          </div>
          <div className="md:w-2/3 w-10/12 flex flex-col gap-3">
            <h1 className="text-center font-medium text-4xl sm:text-5xl">
              Affordable Concierge Doctor At Your Fingertips
            </h1>
            <p className="text-center sm:text-base text-xs">
              Access integrated virtual and in-person consultation at home when
              you need it
            </p>
          </div>
          <div className="w-full flex items-center justify-center">
            <button onClick={showModal} type="button" className="bg-new-10 text-white border border-white-100 rounded-3xl h-14 w-4/5 sm:w-96 mt-6 mb-6">
              Request a provider visit
              <CaretRightFilled className="text-white ml-5" data-sentry-element="CaretRightFilled" data-sentry-source-file="HeroSection2.jsx" />
            </button>
          </div>
        </div>
        <div className="mt-16 sm:mt-8 w-full max-w-[1920px] flex items-center justify-center px-4 sm:px-10 overflow-x-auto transition-all ease-in-out duration-200 noscroll snap-x snap-mandatory snap-center">
          <div className={`w-full max-w-[1440px] flex items-center ${providers?.length < 5 ? "justify-start lg:justify-center" : "justify-start"} overflow-x-auto gap-5 pb-3 noscroll transition-all ease-in-out duration-200 snap-x snap-mandatory snap-center`}
        // ref={scrollContainerRef}
        >
            {providers?.map((item, index) => {
            return <ProviderSummaryCard2 key={item.id} rate={index} fullName={item.first_name + " " + item.last_name} headline={item.headline} photo={item.photo} id={item.id} email={item.username} bioButtonColor="new2-30" bioBorderColor="border-new2-30" bioTextColor="text-new2-30" />;
          })}
          </div>
          {/* <div className="w-full flex items-center justify-end">
            <span className="flex items-center gap-5 md:gap-10 mt-8">
              <button onClick={handlePrevious} type="button">
                <Image
                  src={"/prevNext.svg"}
                  className="w-14 cursor-pointer aspect-square"
                  alt="arrow"
                  width={60}
                  height={60}
                />
              </button>
              <button onClick={handleNext} type="button">
                <Image
                  src={"/prevNext.svg"}
                  className="rotate-180 w-14 cursor-pointer aspect-square"
                  alt="arrow"
                  width={60}
                  height={60}
                />
              </button>
            </span>
           </div> */}
        </div>
      </div>

      {/* better hero */}
      <div className="sm:bg-hero-blur bg-hero-mobile-blur bg-[center_top_20%] sm:bg-bottom bg-no-repeat bg-[length:100%] sm:bg-cover w-full flex flex-col items-center justify-start pt-40 sm:pt-60 pb-20 sm:pb-20 md:gap-5 gap-5">
        <div className="relative w-full max-w-[1440px] px-4 md:px-16 lg:px-32 flex flex-col items-center justify-start md:gap-5 gap-5">
          <div className="absolute sm:flex hidden sm:left-[10%] left-2 -top-10 sm:top-[10%] px-3 text-xs text-[#808080] h-8 bg-white rounded-full items-center gap-2">
            <Image src={"/pinkeyefloat.svg"} alt="a" className="" width={15} height={15} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
            Pink Eye
          </div>
          <div className="absolute sm:hidden sm:left-[10%] left-2 -top-10 sm:top-[10%] px-3 text-xs text-[#808080] h-8 bg-white rounded-full flex items-center gap-2">
            <Image src={"/pinkeyefloat.svg"} alt="a" className="" width={15} height={15} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
            Pink Eye
          </div>
          <Image src={"/sicknessfloat.svg"} alt="a" className="absolute hidden sm:block sm:right-[10%] sm:top-[20%] -top-10 right-2" width={50} height={50} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <Image src={"/sicknessfloat.svg"} alt="a" className="absolute sm:hidden sm:right-[10%] sm:top-[20%] -top-10 right-2" width={50} height={50} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <Image src={"/allergyfloat.svg"} alt="a" className="absolute sm:left-[13%] sm:top-[60%] left-2 top-[50%] hidden sm:flex" width={80} height={80} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <Image src={"/feverfloat.svg"} alt="a" className="absolute sm:right-[7.5%] sm:top-[55%] right-2 -bottom-10 h-10 sm:h-auto" width={60} height={60} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <div className="absolute sm:left-[15%] sm:top-[80%] left-2 -bottom-10 px-3 text-xs text-[#808080] h-8 bg-white rounded-full flex items-center gap-2">
            <Image src={"/headachesfloat.svg"} alt="a" className="" width={15} height={15} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
            Headaches
          </div>
          <Image src={"/flufloat.svg"} alt="a" className="absolute sm:hidden flex left-2 top-[45%] h-8" width={80} height={80} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <Image src={"/flufloat.svg"} alt="a" className="absolute hidden sm:flex right-[15%] top-[90%]" width={80} height={80} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />
          <div className="flex items-center gap-2 sm:text-base text-xs">
            <span className="font-semibold text-xs">For patients</span>
            <Switch size="small" onChange={handleRoute} data-sentry-element="Switch" data-sentry-source-file="HeroSection2.jsx" />
            <span className="text-xs">For providers</span>
          </div>
          <div className="md:w-3/4 w-10/12 flex flex-col gap-3">
            <h1 className="text-center font-medium text-4xl sm:text-5xl">
              Affordable Concierge Doctor At Your Fingertips
            </h1>
            <p className="text-center sm:text-base text-xs font-medium">
              Access integrated virtual and in-person consultation at home when
              you need it
            </p>
          </div>
          <div className="w-full flex items-center justify-center">
            <button onClick={showModal} type="button" className="bg-new-10 text-white hover:bg-new2-40 transition-all ease-in-out duration-300 rounded-3xl h-14 w-4/5 sm:w-80 mt-6 mb-6 sm:mt-0">
              Request a provider visit
              <CaretRightFilled className="text-white ml-5" data-sentry-element="CaretRightFilled" data-sentry-source-file="HeroSection2.jsx" />
            </button>
          </div>
        </div>
        <div className="mt-16 sm:mt-8 w-full max-w-[1920px] flex items-center justify-center px-4 sm:px-10 overflow-x-auto transition-all ease-in-out duration-200 noscroll snap-x snap-mandatory snap-center">
          <div className={`w-full max-w-[1440px] flex items-center ${providers?.length < 5 ? "justify-start lg:justify-center" : "justify-start"} overflow-x-auto gap-3 sm:gap-5 pb-3 noscroll transition-all ease-in-out duration-200 snap-x snap-mandatory snap-center`}
        // ref={scrollContainerRef}
        >
            {providers?.map((item, index) => {
            return <ProviderSummaryCard2 key={item.id} rate={index} fullName={item.first_name + " " + item.last_name} headline={item.headline} photo={item.photo} id={item.id} email={item.username} bioButtonColor="[#808080]" bioBorderColor="border-[#808080]" bioTextColor="text-new2-30" />;
          })}
          </div>
          {/* <div className="w-full flex items-center justify-end">
            <span className="flex items-center gap-5 md:gap-10 mt-8">
              <button onClick={handlePrevious} type="button">
                <Image
                  src={"/prevNext.svg"}
                  className="w-14 cursor-pointer aspect-square"
                  alt="arrow"
                  width={60}
                  height={60}
                />
              </button>
              <button onClick={handleNext} type="button">
                <Image
                  src={"/prevNext.svg"}
                  className="rotate-180 w-14 cursor-pointer aspect-square"
                  alt="arrow"
                  width={60}
                  height={60}
                />
              </button>
            </span>
           </div> */}
        </div>
        <div className="w-11/12 max-w-[1440px] px-3 md:px-16 lg:px-32 rounded-3xl bg-white sm:hidden flex flex-col items-start justify-start p-6 sm:p-10 gap-5 mt-10">
          <h2 className="text-new-20 font-medium text-4xl text-[#1f1855] w-full text-center">
            Why Join Dinma
          </h2>
          <div className="w-full flex flex-col md:flex-row text-[#808080] items-center justify-start gap-8 md:gap-10">
            <ul className="space-y-8 pl-8 sm:pl-0">
              <li className="flex items-center gap-2">
                <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />{" "}
                Access to technology for easy scheduling
              </li>
              <li className="flex items-center gap-2">
                <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />{" "}
                Concierge service with virtual and in-person consultation at
                home or preferred location
              </li>
              <li className="flex items-center gap-2">
                <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />{" "}
                At-home labs and vaccine administration
              </li>
            </ul>
            <ul className="space-y-8 pl-8 sm:pl-0">
              <li className="flex items-center gap-2">
                <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />{" "}
                Same day and expedited appointment
              </li>
              <li className="flex items-center gap-2">
                <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />{" "}
                Personalized preventive health plans to keep you out of urgent
                care or emergency rooms
              </li>
              <li className="flex items-center gap-2">
                <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="HeroSection2.jsx" />{" "}
                Extended and unrushed visits
              </li>
            </ul>
          </div>
          <div className="w-full pl-8 sm:pl-0">
            <Button type="primary" onClick={() => router.push("/patient/membership")} size="large" className="bg-new2-20 text-white border-none h-12 outline-none rounded-2xl px-10 text-sm font-light" data-sentry-element="Button" data-sentry-source-file="HeroSection2.jsx">
              Join Dinma
              <CaretRightFilled className="text-white" data-sentry-element="CaretRightFilled" data-sentry-source-file="HeroSection2.jsx" />
            </Button>
          </div>
        </div>
      </div>
    </section>;
};
export default HeroSection2;