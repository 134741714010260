"use client";

import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { usePreviousPath } from "../contexts/PreviousPathContext";
import { useState } from "react";
const HomeHeader2 = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const {
    previousPath,
    setPreviousPath
  } = usePreviousPath();
  const pathname = usePathname();
  const handlePrevPath = () => {
    setPreviousPath(pathname);
  };
  const toggleNav = () => {
    setMobileNav(!mobileNav);
  };
  return <div className="w-screen max-w-[1440px] flex items-center justify-center bg-transparent z-50 py-4 fixed" data-sentry-component="HomeHeader2" data-sentry-source-file="HomeHeader2.jsx">
      <div className={`w-11/12 xl:w-10/12 2xl:w-10/12 rounded-full sm:rounded-[30px] bg-new2-10 navshadow h-14 sm:h-20`}>
        <div className="w-full h-full flex justify-between items-center px-4 relative">
          <Link href="/" data-sentry-element="Link" data-sentry-source-file="HomeHeader2.jsx">
            <Image src={"/newdarklogo.png"} alt="dinma logo" className="w-24 sm:w-32" width={200} height={200} data-sentry-element="Image" data-sentry-source-file="HomeHeader2.jsx" />
          </Link>
          <nav className="w-fit hidden sm:flex">
            <ul className="w-full hidden md:flex items-center gap-5">
              <li>
                <Link className="p-2" href="/about" data-sentry-element="Link" data-sentry-source-file="HomeHeader2.jsx">
                  About
                </Link>
              </li>
              <li>
                <Link className="p-2" href="/patient/membership" data-sentry-element="Link" data-sentry-source-file="HomeHeader2.jsx">
                  Membership
                </Link>
              </li>
              <li>
                <Link className="p-2" href="/blog" data-sentry-element="Link" data-sentry-source-file="HomeHeader2.jsx">
                  Blog
                </Link>
              </li>
              <li>
                <Link className="p-2" href="/help-center" data-sentry-element="Link" data-sentry-source-file="HomeHeader2.jsx">
                  Contact us
                </Link>
              </li>
              <li onClick={handlePrevPath}>
                <Link className="p-2" href="/login" data-sentry-element="Link" data-sentry-source-file="HomeHeader2.jsx">
                  Login
                </Link>
              </li>
              <li>
                <Link className="py-4 px-10 bg-parent-400 text-white rounded-3xl" href={pathname === "/provider" ? "/provider/signup" : "/patient/signup"} data-sentry-element="Link" data-sentry-source-file="HomeHeader2.jsx">
                  Sign up
                </Link>
              </li>
            </ul>
          </nav>
          <div className="flex p-1 sm:hidden">
            {mobileNav ? <CloseOutlined onClick={toggleNav} className="text-black font-medium text-2xl" /> : <MenuOutlined onClick={toggleNav} className="text-black font-medium text-2xl" />}
          </div>
          {mobileNav ? <nav className="w-full absolute left-0 top-20 flex items-center justify-end">
              <ul className="w-1/2 flex flex-col items-end justify-end gap-5 bg-white rounded-2xl p-4">
                <li>
                  <Link className="p-2" href="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link className="p-2" href="/patient/membership">
                    Membership
                  </Link>
                </li>
                <li>
                  <Link className="p-2" href="/blog">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link className="p-2" href="/help-center">
                    Contact us
                  </Link>
                </li>
                <li onClick={handlePrevPath}>
                  <Link className="p-2" href="/login">
                    Login
                  </Link>
                </li>
                <li>
                  <Link className="py-2 px-6 bg-parent-400 text-white rounded-full" href={pathname === "/provider" ? "/provider/signup" : "/patient/signup"}>
                    Sign up
                  </Link>
                </li>
              </ul>
            </nav> : ""}
        </div>
      </div>
    </div>;
};
export default HomeHeader2;