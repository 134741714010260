"use client";

import React, { createContext, useContext, useState, useMemo } from "react";
export const MembershipContext = createContext({
  setPractices: () => {}
});
const MembershipProvider = ({
  children
}) => {
  const [practices, setPractices] = useState([]);
  const [selectedPractice, setSelectedPractice] = useState({});
  const [selectedTier, setSelectedTier] = useState("");
  const [selectedInsurance, setSelectedInsurance] = useState("");
  const [cardVerified, setCardVerified] = useState(false);
  const [zipcode, setZipcode] = useState("");
  const values = useMemo(() => ({
    practices,
    setPractices,
    selectedPractice,
    setSelectedPractice,
    selectedTier,
    setSelectedTier,
    selectedInsurance,
    setSelectedInsurance,
    cardVerified,
    setCardVerified,
    zipcode,
    setZipcode
  }), [practices, setPractices, selectedPractice, setSelectedPractice, selectedTier, setSelectedTier, selectedInsurance, setSelectedInsurance, cardVerified, setCardVerified, zipcode, setZipcode]);
  return <MembershipContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="MembershipProvider" data-sentry-source-file="MembershipProvider.jsx">
      {children}
    </MembershipContext.Provider>;
};
const useMembership = () => {
  const context = useContext(MembershipContext);
  if (context === undefined) {
    throw Error("useMembership() MUST BE USED WITHIN A <MembershipProvider/> !!!");
  }
  return context;
};
export { MembershipProvider, useMembership };