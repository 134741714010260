const BigWhiteCard = ({
  title,
  title2,
  subtitle,
  children
}) => {
  return <div className="w-full sm:w-[600px] h-fit sm:h-[495px] flex flex-col items-start justify-start bg-white shadow-md  rounded-2xl p-4 sm:p-9" data-sentry-component="BigWhiteCard" data-sentry-source-file="BigWhiteCard.jsx">
      <h1 className="hidden sm:flex font-medium text-3xl sm:text-4xl w-3/4 sm:pl-7 text-[#1f1855]">
        {title}
      </h1>
      <h1 className="flex sm:hidden font-medium text-3xl sm:text-4xl w-4/5 sm:pl-7 text-[#1f1855]">
        {title2}
      </h1>
      <span className="text-xl sm:w-11/12 sm:pl-7 text-[#808080]">
        {subtitle}
      </span>
      <div className="w-full flex items-center justify-center">{children}</div>
    </div>;
};
export default BigWhiteCard;