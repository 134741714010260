import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/codebuild/output/src3260641167/src/Dinma-webapp-frontend/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["BookingProvider"] */ "/codebuild/output/src3260641167/src/Dinma-webapp-frontend/app/contexts/BookingProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["MembershipProvider"] */ "/codebuild/output/src3260641167/src/Dinma-webapp-frontend/app/contexts/MembershipProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreviousPathProvider"] */ "/codebuild/output/src3260641167/src/Dinma-webapp-frontend/app/contexts/PreviousPathContext.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3260641167/src/Dinma-webapp-frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"oldinter\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3260641167/src/Dinma-webapp-frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.jsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\",\"style\":\"normal\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3260641167/src/Dinma-webapp-frontend/app/globals.css");
