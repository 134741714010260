import { Modal, Rate } from "antd";
import { useState } from "react";
import { useRouter } from "next/navigation";
import RequestCare from "./RequestCare";
import { useBooking } from "../contexts/BookingProvider";
import Image from "next/image";
import Link from "next/link";
const ProviderSummaryCard2 = ({
  // rate,
  headline,
  fullName,
  id,
  email,
  // book now will showModal and put this in Ls or <BookingContext/>
  photo,
  bioTextColor,
  bioButtonColor,
  bioBorderColor,
  bgColor
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const router = useRouter();
  const {
    setFlow,
    setProviderEmail
  } = useBooking();
  const showModal = () => {
    // this function now routes to the request care page instead of setIsModalOpen(true).
    localStorage.setItem("providerEmail", email); // not sure this is useful
    localStorage.setItem("intent", "direct"); // not sure this is useful
    setFlow("directbooking");
    setProviderEmail(email);
    router.push("/request");
    // setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleFullBio = () => {
    router.push(`/fullbio?id=${encodeURIComponent(id)}`);
    localStorage.setItem("intent", "direct");
    setFlow("directbooking");
    setProviderEmail(email);
  };
  return <div className={`flex flex-col gap-1 sm:gap-1 items-center justify-start max-w-48 min-w-48 sm:min-w-72 sm:max-w-72 ${bgColor ? bgColor : "bg-gradient-to-b from-white to-[#e7deff]"} pb-2 sm:py-[10px] rounded-xl border-2 sm:border-4 border-white`} data-sentry-component="ProviderSummaryCard2" data-sentry-source-file="ProviderSummaryCard2.jsx">
      <div className="w-1/3 mt-10" onClick={handleFullBio}>
        <Image src={photo ? photo : ""} alt="user" className="w-full aspect-square rounded-full object-cover" width={500} height={500} data-sentry-element="Image" data-sentry-source-file="ProviderSummaryCard2.jsx" />
      </div>
      <div className="w-full flex flex-col items-center justify-center sm:gap-1">
        <span className="font-medium sm:text-sm text-xs text-black">
          {fullName}
        </span>
        <span>
          <Rate className="sm:text-sm text-[9px]" disabled defaultValue={5} data-sentry-element="Rate" data-sentry-source-file="ProviderSummaryCard2.jsx" />
        </span>
      </div>
      <div className={`w-full ${bioTextColor} h-5 sm:h-8 overflow-hidden font-normal text-[7.5px] px-3 sm:px-4 text-center`} style={{
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }}>
        {headline}
      </div>
      <div className="w-full flex items-center justify-center sm:gap-2 gap-1 px-2 sm:px-4 mt-3">
        <button type="button" className={`text-${bioButtonColor} border ${bioBorderColor} rounded-full h-7 sm:h-10 w-fit px-2 sm:px-4 font-light text-[10px]`} onClick={handleFullBio}>
          Full Bio
        </button>
        <button type="button" className="bg-new-10 text-white border border-white rounded-full h-7 sm:h-10 w-fit px-2 sm:px-4 font-light text-[10px]" onClick={showModal}>
          Book Now
        </button>
      </div>
      <Modal width={600} className="p-0 m-0" centered footer={null} title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} data-sentry-element="Modal" data-sentry-source-file="ProviderSummaryCard2.jsx">
        <div className="w-full">
          <RequestCare data-sentry-element="RequestCare" data-sentry-source-file="ProviderSummaryCard2.jsx" />
        </div>
      </Modal>
    </div>;
};
export default ProviderSummaryCard2;