"use client";

import { useState } from "react";
import AccordionItem from "./AccordionItem";
const GeneralFAQAccordion = () => {
  const [curOpen, setCurOpen] = useState(null);
  const arrayOfQueAndAns = [{
    question: "How does Dinma work?",
    answer: "Dinma makes it easy and fast to access primary care for you or your dependents. You can schedule appointments on our website. Visits can be virtual or in-person at your home or a preferred location.",
    id: 1
  }, {
    question: "What are the benefits of using Dinma?",
    answer: `<li className="text-sm sm:text-base text-white">
        No commutes or long wait times for primary or urgent care.
      </li>
      <li className="text-sm sm:text-base">
        Expedited appointments.
      </li>
      <li className="text-sm sm:text-base">
        Unhurried, extended visits.
      </li>
      <li className="text-sm sm:text-base">
        High-quality care with at-home services like labs and vaccines.
      </li>
      <li className="text-sm sm:text-base">
        Personalized chronic disease management.
      </li>`,
    id: 2,
    isHtml: true
  }, {
    question: "What conditions can you treat?",
    answer: `<p className="text-sm sm:text-base text-white">
      We provide care for:
    </p>
    <li className="text-sm sm:text-base">
    Acute and chronic conditions (e.g., colds, infections, allergies)
      </li>
      <li className="text-sm sm:text-base">
      Wellness visits (e.g., physicals, preventive care)
      </li>
      <li className="text-sm sm:text-base">
      Any condition typically treated in a doctor’s office or urgent care center.
      </li>
    `,
    id: 3,
    isHtml: true
  }, {
    question: "Do you accept insurance?",
    answer: `<p className="text-sm sm:text-base text-white">
        Yes, we accept most major insurance plans, including:
      </p>
        <li>UnitedHealthcare</li>
        <li>Cigna</li>
        <li>Aetna</li>
        <li>Humana</li>
        <li>Anthem</li>
      <p className="text-sm sm:text-base">
        We are continually adding more plans, so check with us if you don’t see your provider listed.
      </p>`,
    id: 4,
    isHtml: true
  }, {
    question: "How much does Dinma cost?",
    answer: `<li className="text-sm sm:text-base text-white">
        <strong>Insured Patients:</strong> If we accept your insurance, you pay your patient responsibility (e.g., copay, coinsurance, deductible) plus a $25 monthly membership fee.
      </li>
      <li className="text-sm sm:text-base">
        <strong>Non-Insured Patients:</strong> If we do not accept your insurance, you pay a $65 monthly membership fee.
      </li>`,
    id: 5,
    isHtml: true
  }, {
    question: "Can I use FSA, HSA, or HRA cards?",
    answer: "Yes! You can use these cards to pay for your patient responsibility or monthly membership fee.",
    id: 6
  }, {
    question: "Is my information secure?",
    answer: "Yes, we take your privacy seriously. All your personal and medical information is securely stored and protected.",
    id: 7
  }, {
    question: "What areas do you serve?",
    answer: "Currently, we provide services in Alpharetta, Roswell, Johns Creek, Marietta, and Smyrna. We are continuously expanding to additional locations, so email us at admin@getdinma.com if your location is not listed.",
    id: 8
  }, {
    question: "What should I expect during my appointment?",
    answer: "Our provider will review your concerns, offer a thorough evaluation, and create a personalized care plan. For in-person visits, we bring all necessary equipment to your home. We will also take your lab samples and administer vaccines at home as needed.",
    id: 9
  }, {
    question: "How do I cancel or reschedule an appointment?",
    answer: "You can manage your appointments directly through our app or by contacting our support team.",
    id: 10
  }];
  return <div className="w-full max-w-[1920px]" data-sentry-component="GeneralFAQAccordion" data-sentry-source-file="GeneralFAQAccordion.jsx">
      {arrayOfQueAndAns.map(e => {
      return <AccordionItem object={e} key={e.id} curOpen={curOpen} onOpen={setCurOpen} />;
    })}
    </div>;
};
export default GeneralFAQAccordion;