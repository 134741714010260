"use client";

import HeroSection from "./components/HeroSection";
import HeroSection2 from "./components/HeroSection2";
import HomeHeader from "./components/HomeHeader";
import HomeHeader2 from "./components/HomeHeader2";
import BlogSection from "./components/BlogSection";
import MoreSection from "./components/MoreSection";
import MoreSection2 from "./components/MoreSection2";
import Footer from "./components/Footer";
export default function Home() {
  return <main className="flex w-full h-full flex-col items-center justify-start" data-sentry-component="Home" data-sentry-source-file="page.jsx">
      <HomeHeader2 data-sentry-element="HomeHeader2" data-sentry-source-file="page.jsx" />
      <HeroSection2 data-sentry-element="HeroSection2" data-sentry-source-file="page.jsx" />
      {/* <BlogSection /> */}
      <MoreSection2 data-sentry-element="MoreSection2" data-sentry-source-file="page.jsx" />
      <Footer data-sentry-element="Footer" data-sentry-source-file="page.jsx" />
    </main>;
}