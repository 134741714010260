"use client";

import { useRouter } from "next/navigation";
import Image from "next/image";
import { Button, Form, Input, Modal } from "antd";
import { CaretRightFilled } from "@ant-design/icons";
import FloatingPurpleCard from "./FloatingPurpleCard";
import BigWhiteCard from "./BigWhiteCard";
import BigPurpleCard from "./BigPurpleCard";
import TestimonialCard from "./TestimonialCard";
import { useEffect, useRef, useState } from "react";
import { useBooking } from "../contexts/BookingProvider";
import GeneralFAQAccordion from "./GeneralFAQAccordion";
import axios from "axios";
import { baseUrl } from "../resources/resources";
const {
  TextArea
} = Input;
const MoreSection2 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    comment: ""
  });
  const [partnerForm] = Form.useForm();
  const {
    setFlow,
    setIsNewUser
  } = useBooking();
  const router = useRouter();
  useEffect(() => {
    localStorage.removeItem("intent");
    setFlow(null);
    setIsNewUser("null");
  }, []);
  const handlePartnerWithUs = async () => {
    try {
      const response = await axios.post(baseUrl + "/authentication/save_interested_user_email/", formFields, {
        headers: {
          "Content-Type": "application/json"
        }
      });
    } catch (err) {
      console.error("Error subscribing to newsletter:", err);
    }
  };
  const handleChange = (key, value) => {
    setContactDetails(prevInfo => ({
      ...prevInfo,
      [key]: value
    }));
  };
  const showModal = () => {
    // this function now routes to the request care page instead of setIsModalOpen(true).
    router.push("/request");
    setFlow("homepage");
    // setIsModalOpen(true);
  };
  const containerRef = useRef(null);
  useEffect(() => {
    // Center the middle card on mount
    const container = containerRef.current;
    if (container) {
      const middleCardIndex = Math.floor(container.children.length / 2);
      const middleCard = container.children[middleCardIndex];
      const offset = middleCard.offsetLeft - container.clientWidth / 2 + middleCard.clientWidth / 2;
      container.scrollTo({
        left: offset,
        behavior: "smooth"
      });
    }
  }, []);
  const showPartnerModal = () => {
    setIsPartnerModalOpen(true);
  };
  const handlePartnerOk = () => {
    setIsPartnerModalOpen(false);
  };
  const handlePartnerCancel = () => {
    setIsPartnerModalOpen(false);
  };
  return <section className="w-full items-center justify-center pt-8 md:pt-14 pb-12 md:pb-20 px-4 sm:px-0 bg-new2-10 text-new2-30 flex flex-col gap-10" data-sentry-component="MoreSection2" data-sentry-source-file="MoreSection2.jsx">
      <div className="w-11/12 max-w-[1440px] px-4 sm:px-8 rounded-3xl bg-white sm:flex hidden flex-col items-start justify-start p-6 sm:p-9 gap-5">
        <Modal width={600} className="p-0 m-0" centered footer={null} title={<span className="text-sm sm:text-base">
              Complete the information and we will get back to you.
            </span>} open={isPartnerModalOpen} onOk={handlePartnerOk} onCancel={handlePartnerCancel} data-sentry-element="Modal" data-sentry-source-file="MoreSection2.jsx">
          <div className="w-full">
            <Form form={partnerForm} size="large" layout="vertical" name="partner_with_us" data-sentry-element="Form" data-sentry-source-file="MoreSection2.jsx">
              <Form.Item label="Full Name" data-sentry-element="unknown" data-sentry-source-file="MoreSection2.jsx">
                <Input placeholder="John Doe" onChange={e => handleChange("fullname", e.target.value)} data-sentry-element="Input" data-sentry-source-file="MoreSection2.jsx" />
              </Form.Item>
              <Form.Item label="Phone Number" validateStatus={contactDetails.phone_number && contactDetails.phone_number.length !== 10 ? "error" : ""} help={contactDetails.phone_number && contactDetails.phone_number.length !== 10 ? "Phone number must be 10 digits" : ""} hasFeedback data-sentry-element="unknown" data-sentry-source-file="MoreSection2.jsx">
                <Input placeholder="Phone Number" type="tel" className="w-full" maxLength={10} value={contactDetails.phone_number} onChange={e => {
                const value = e.target.value;
                // Allow only numbers and limit to 10 digits
                if (/^\d*$/.test(value) && value.length <= 10) {
                  handleChange("phone_number", value);
                }
              }} data-sentry-element="Input" data-sentry-source-file="MoreSection2.jsx" />
              </Form.Item>
              <Form.Item label="Email" rules={[{
              type: "email",
              message: "Enter a valid email"
            }]} data-sentry-element="unknown" data-sentry-source-file="MoreSection2.jsx">
                <Input placeholder="Email Address" onChange={e => handleChange("email", e.target.value)} data-sentry-element="Input" data-sentry-source-file="MoreSection2.jsx" />
              </Form.Item>
              <Form.Item label="Comment" data-sentry-element="unknown" data-sentry-source-file="MoreSection2.jsx">
                <TextArea onChange={e => handleChange("comment", e.target.value)} autoSize data-sentry-element="TextArea" data-sentry-source-file="MoreSection2.jsx" />
              </Form.Item>
              <Form.Item className="w-full flex items-center justify-center" data-sentry-element="unknown" data-sentry-source-file="MoreSection2.jsx">
                <Button type="primary" className="rounded-full px-4 w-full md:w-fit" size="large" onClick={handlePartnerWithUs} data-sentry-element="Button" data-sentry-source-file="MoreSection2.jsx">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <h2 className="text-new-20 font-medium text-4xl">Why Join Dinma</h2>
        <div className="w-full flex flex-col md:flex-row items-center justify-start gap-10">
          <ul className="space-y-5 text-[#808080]">
            <li className="flex items-center gap-2">
              <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />{" "}
              Access to technology for easy scheduling
            </li>
            <li className="flex items-center gap-2">
              <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />{" "}
              Concierge service with virtual and in-person consultation at home
              or preferred location
            </li>
            <li className="flex items-center gap-2">
              <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />{" "}
              At-home labs and vaccine administration
            </li>
          </ul>
          <ul className="space-y-5">
            <li className="flex items-center gap-2">
              <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />{" "}
              Same day and expedited appointment
            </li>
            <li className="flex items-center gap-2">
              <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />{" "}
              Personalized preventive health plans to keep you out of urgent
              care or emergency rooms
            </li>
            <li className="flex items-center gap-2">
              <Image src={"/checkmark.svg"} alt="i" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />{" "}
              Extended and unrushed visits
            </li>
          </ul>
        </div>
        <div className="w-full">
          <Button type="primary" onClick={() => router.push("/patient/membership")} size="large" className="bg-new2-20 text-white border-none outline-none rounded-2xl px-8 text-sm font-light" data-sentry-element="Button" data-sentry-source-file="MoreSection2.jsx">
            Join Dinma
            <CaretRightFilled className="text-white" data-sentry-element="CaretRightFilled" data-sentry-source-file="MoreSection2.jsx" />
          </Button>
        </div>
      </div>

      <div className="w-full max-w-[1440px] px-4 md:px-16 lg:px-32 flex flex-col items-center justify-start -mt-14 sm:-mt-0">
        <div className="w-full md:w-7/12 text-center px-4 flex flex-col items-center justify-center gap-2">
          <span className="sm:w-full w-3/4 font-medium leading-tight">
            Ditch the Hassle. Embrace Convenient, Personalized Care.
          </span>
          <h1 className="sm:font-medium font-semibold text-lg w-3/4 sm:w-full sm:text-3xl text-new2-20 leading-tight">
            Why go to the clinic when we can come to you?
          </h1>
          <p className="w-full text-center text-sm sm:text-base">
            Dinma brings modern, personalized healthcare to your home—whenever
            you need it. Say goodbye to long waits, exposure to crowded waiting
            rooms, and the inconvenience of travel. Request a provider with just
            a few clicks and get the care you deserve, on your schedule.
          </p>
        </div>
        <div className="bg-ditch-hassle bg-no-repeat bg-center bg-cover w-full max-w-[1050px] flex flex-col items-center justify-center relative h-64 sm:h-[640px]">
          <FloatingPurpleCard title="I'm glad the doctor is on the way" subtitle="Request a home visit in a few clicks." className="absolute sm:bottom-52 sm:-left-10 bottom-4 left-4" data-sentry-element="FloatingPurpleCard" data-sentry-source-file="MoreSection2.jsx" />
          <FloatingPurpleCard title="Excited to be treated at home" subtitle="A licensed provider arrives at your home with everything needed to treat you" className="absolute sm:top-44 sm:-right-10 top-3 right-4" data-sentry-element="FloatingPurpleCard" data-sentry-source-file="MoreSection2.jsx" />
        </div>
      </div>

      <div className="w-full max-w-[1440px] px-4 md:px-16 flex flex-col sm:flex-row items-center justify-center gap-2 sm:gap-5 sm:-mt-10">
        <BigWhiteCard title={`Redefining\nPersonalized Care`} title2={"Redefining Personalized Care"} subtitle="We've transformed how you receive care—leveraging advanced technology alongside a compassionate, human-first approach." data-sentry-element="BigWhiteCard" data-sentry-source-file="MoreSection2.jsx">
          <div className="w-full h-[170px] sm:h-[300px] relative">
            <Image className="absolute sm:-left-5 sm:top-8 sm:w-96 sm:h-60 w-80 h-48 -left-10 top-0" src={"/smallwhitecard.svg"} alt="1" width={700} height={700} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
            <Image className="absolute sm:-right-10 sm:-bottom-2 sm:w-96 sm:h-60 w-80 h-48 -right-10 -bottom-0" src={"/smallgraycard.svg"} alt="2" width={700} height={700} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
          </div>
        </BigWhiteCard>
        <BigWhiteCard title="Expert Providers at your Doorstep" title2="Quality Care, Tailored to You" subtitle="From preventive care to sick visits, our licensed providers deliver care via virtual consultations or in-person home visits." data-sentry-element="BigWhiteCard" data-sentry-source-file="MoreSection2.jsx">
          <div className="w-full h-[190px] sm:h-[300px] flex item start justify-start relative">
            <Image className="sm:flex hidden absolute -left-5 bottom-5" src={"/bwc2content.svg"} alt="3" width={700} height={700} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
            <Image className="sm:hidden flex absolute -left-8" src={"/bwc2content2.svg"} alt="3" width={700} height={700} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
          </div>
        </BigWhiteCard>
      </div>

      <div className="w-full max-w-[1440px] px-4 md:px-16 lg:px-20 xl:px-28 flex flex-col items-start justify-start gap-2 sm:gap-5">
        <h1 className="font-medium text-3xl sm:text-4xl sm:pr-7 w-11/12 sm:w-1/2 text-parent-400 mt-5">
          Dinma makes it easy to connect with top providers, right from your
          home.
        </h1>

        <p className="sm:text-xl text-lg w-full sm:w-3/5 text-[#808080]">
          Avoid messy appointment booking, long wait times, hospitals infection
          and the drive to the doctor&apos;s office
        </p>

        <div className="w-full flex flex-col sm:flex-row items-center justify-start gap-5 mt-5 px-6 sm:px-0">
          <div className="flex flex-col items-center justify-center w-full sm:w-[384px] bg-white p-4 rounded-2xl text-center h-[242px]">
            <Image src={"/newchat.svg"} alt="convenience" className="w-12 h-12 mx-auto" width={200} height={200} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
            <p className="mb-2 md:mb-4 text-lg md:text-xl font-semibold mt-4">
              Covenience
            </p>
            <p className="text-sm md:text-base font-light w-4/5 sm:w-full text-[#808080] h-16 flex flex-col items-center justify-start">
              Healthcare tailored to your needs, delivered whenever you need
              it—whether virtually or in the comfort of your home
            </p>
          </div>
          <div className=" flex flex-col items-center justify-center w-full sm:w-[384px] bg-white p-4 rounded-2xl text-center h-[242px]">
            <Image src={"/newcharge.svg"} alt="transparency" className="w-12 h-12 mx-auto" width={200} height={200} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
            <p className="mb-2 md:mb-4 text-lg md:text-xl font-semibold mt-4">
              Transparency
            </p>
            <p className="text-sm md:text-base font-light w-4/5 h-16 sm:w-full text-[#808080]">
              Enjoy upfront pricing for medical consultation.
            </p>
          </div>
          <div className=" flex flex-col items-center justify-center w-full sm:w-[384px] bg-white p-4 rounded-2xl text-center h-[242px]">
            <Image src={"/newquality.svg"} alt="quality" className="w-12 h-12 mx-auto" width={200} height={200} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
            <p className="mb-2 md:mb-4 text-lg md:text-xl font-semibold mt-4">
              Quality
            </p>
            <p className="text-sm md:text-base font-light w-4/5 h-16 sm:w-full text-[#808080]">
              Our priority is to deliver best in class care so you can stay
              healthy
            </p>
          </div>
        </div>
      </div>

      <div className="sm:w-11/12 max-w-[1440px] mt-2 px-3 md:px-16 xl:px-24 2xl:px-32 w-full flex flex-col items-center p-8 justify-start gap-5 bg-[#efedff] sm:rounded-3xl">
        <div className="w-full md:w-1/2 py-2 text-center flex flex-col items-center justify-center gap-2 text-[#808080]">
          <span>How it works</span>
          <h1 className="font-medium text-3xl text-new2-20 w-10/12">
            Dinma brings the doctor to you in 3 steps.
          </h1>
          <p>Ditch the Hassle. Embrace Convenient, Personalized Care.</p>
        </div>

        <div className="w-full flex items-center sm:justify-center gap-5 mt-5 overflow-x-auto noscroll">
          <div ref={containerRef} className="w-fit flex items-center justify-center sm:justify-center gap-2 sm:gap-2">
            <div className="sm:flex-1 w-[80vw] sm:w-auto bg-white px-4 pb-4 rounded-2xl text-center">
              <Image src={"/firsthowitworks.png"} alt="hand" className="mx-auto" width={1000} height={1000} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
              <h4 className="font-medium xl:text-xl 2xl:text-2xl text-[#1f1855] w-full text-left mt-5">
                Enter your symptom, age, and location to request care.
              </h4>
              <p className="w-full text-left text-xs 2xl:text-sm">
                No need for paperwork to get treated. Tell us your symptoms and
                zip code and our algorithm will show you the best provider
                options
              </p>
            </div>
            <div className="sm:flex-1 w-[80vw] sm:w-auto bg-white p-4 py-8 rounded-2xl text-center">
              <Image src={"/secondhowitworks.png"} alt="dr" className="mx-auto" width={1000} height={1000} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
              <h4 className="font-medium xl:text-xl 2xl:text-2xl text-[#1f1855] w-full text-left">
                Choose Your Provider
              </h4>
              <p className="w-full text-left text-xs 2xl:text-sm">
                Review a curated list of qualified healthcare professionals near
                you. Each provider is vetted to ensure they meet our high
                standards for care.
              </p>
            </div>
            <div className="sm:flex-1 w-[80vw] sm:w-auto bg-white px-4 pb-4 lg:pt-0 xl:pt-8 rounded-2xl text-center">
              <Image src={"/thirdhowitworks.png"} alt="treat" className="mx-auto" width={500} height={500} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
              <h4 className="font-medium xl:text-xl 2xl:text-2xl text-[#1f1855] w-full text-left">
                Get Treated Online or at Home
              </h4>
              <p className="w-full text-left text-xs 2xl:text-sm">
                Whether it&apos;s a persistent cough, chronic condition or a
                routine check-up, our providers see you online or arrive with
                everything needed to treat you at home at the scheduled time.
              </p>
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-center">
          <button onClick={showModal} type="button" className="bg-new-10 text-white rounded-3xl h-14 w-10/12 sm:w-96 mt-6 mb-6">
            Request a provider visit
            <CaretRightFilled className="text-white ml-5" data-sentry-element="CaretRightFilled" data-sentry-source-file="MoreSection2.jsx" />
          </button>
        </div>
      </div>

      <div className="w-full max-w-[1440px] px-4 md:px-16 lg:px-20 flex flex-col items-center p-8 justify-start gap-5 sm:-mt-5 -mt-10">
        <div className="w-full md:w-1/2 text-center flex flex-col items-center justify-center gap-2">
          <h1 className="font-medium text-3xl text-new2-20 sm:w-10/12">
            Our providers accept these insurance
          </h1>
          <p className=" sm:w-10/12 text-[#808080]">
            Keep an eye on this page as we are continuously adding new insurance
          </p>
        </div>

        <div className="w-full hidden sm:flex items-center justify-center">
          <Image src={"/supportedinsurance.svg"} alt="insurances" className="mx-auto" width={1000} height={1000} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
        </div>

        <div className="w-full flex sm:hidden items-center justify-center">
          <Image src={"/mobilesupportedinsurance.svg"} alt="insurances" className="mx-auto" width={1000} height={1000} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
        </div>

        <div className="w-full md:w-1/2 text-center flex flex-col items-center justify-center gap-5">
          <h1 className="font-medium text-3xl text-new2-20">Pricing</h1>
          <p className="hidden sm:flex">
            We accept insurance and also require patients purchase a membership
            for concierge medicine access
          </p>
          <p className="sm:hidden flex">
            We have considered you in our pricing, thats why we provide several
            options to receive good care when you use Dinma. We accept insurance
            and also require patients purchase a membership
          </p>
        </div>

        <div className="w-full flex flex-col sm:flex-row items-center justify-center gap-5 mt-5">
          <div className="flex-1 bg-[#efedff] hover:bg-new2-40 hover:text-white p-4 sm:px-8 rounded-3xl text-center h-[400px] flex flex-col items-center justify-center">
            <Image src={"/insuranceillus.svg"} alt="insurance" className="mx-auto" width={200} height={200} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
            <p className="mb-2 md:mb-4 text-lg md:text-xl font-semibold mt-4">
              Insurance
            </p>
            <p className="text-sm md:text-sm font-light px-4 sm:px-0">
              This helps cover medical costs, reducing or eliminating
              out-of-pocket expenses for treatments, consultations, and
              procedures
            </p>
          </div>
          <div onClick={() => router.push("/patient/membership")} className="flex-1 hover:bg-[#e9d7fe] bg-white p-8 rounded-2xl text-center h-[400px] flex flex-col items-center justify-center">
            <Image src={"/membership.svg"} alt="membership" className="mx-auto" width={200} height={200} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
            <p className="mb-2 md:mb-4 text-lg md:text-xl font-semibold mt-4">
              Membership
            </p>
            <p className="text-sm md:text-base font-light px-4 sm:px-0">
              This offers exclusive concierge services, like no paperwork,
              priority appointments, at-home labs, vaccines, consultations, and
              seamless tech access for personalized care.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full max-w-[1440px] px-4 md:px-16 lg:px-20 flex flex-col items-center sm:p-8 justify-start gap-5 sm:-mt-10">
        <div className="w-full md:w-1/2 text-center hidden sm:flex flex-col items-center justify-center gap-2">
          <h1 className="font-medium text-3xl text-new2-20">Why Dinma?</h1>
          <p className="hidden sm:flex sm:w-10/12">
            Dinma isn&apos;t just about convenience. It&apos;s about
            transforming healthcare to fit your life.
          </p>
          <p className="flex sm:hidden">
            We have considered you in our pricing, thats why we provide several
            options to receive good care when you use Dinma. We accept insurance
            and also require patients purchase a membership
          </p>
        </div>

        <div className="w-full flex flex-col items-center justify-center gap-2">
          <div className="w-full flex items-center justify-center">
            <div className="sm:w-2/5 w-full aspect-square flex-col items-center justify-center">
              <BigPurpleCard bgcolor={"bg-parent-400"} toptext={"Dinma is more than just a service"} title={"The Future of Healthcare, today"} subtitle={"it’s a commitment to your well-being. We’re bringing healthcare into the 21st century, where accessibility, convenience, and quality are no longer mutually exclusive. Whether you need urgent care or routine check-ups, we believe care should revolve around your life—not the other way around."} buttontext={"Partner with us"} onclick={showPartnerModal} data-sentry-element="BigPurpleCard" data-sentry-source-file="MoreSection2.jsx" />
            </div>
            <div className="w-3/5 hidden sm:flex h-full flex-col items-center justify-center border-none outline-none">
              <Image src={"/whydinma1.svg"} alt="quality" className="mx-auto h-full object-cover object-center rounded-3xl border-none outline-none" width={1000} height={1000} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
            </div>
          </div>
          <div className="w-full flex items-center justify-center ">
            <div className="w-3/5 hidden sm:flex h-fit flex-col items-center justify-center">
              <Image src={"/whydinma2.svg"} alt="quality" className="mx-auto h-full object-contain object-center rounded-3xl" width={1000} height={1000} data-sentry-element="Image" data-sentry-source-file="MoreSection2.jsx" />
            </div>
            <div className="w-full h-full sm:w-2/5 aspect-square sm:aspect-video flex-col items-center justify-center">
              <BigPurpleCard bgcolor={"bg-new2-40"} toptext={"We are Next Gen"} title={"Ready to Experience Next Gen Healthcare?"} subtitle={"Request your first home visit in a few clicks. Let Dinma bring the doctor to you, so you can take control of your health with confidence and ease."} buttontext={"Request a provider visit"} onclick={() => router.push("/request")} data-sentry-element="BigPurpleCard" data-sentry-source-file="MoreSection2.jsx" />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full max-w-[1440px] px-4 md:px-16 lg:px-32 text-center text-2xl mb-5 sm:mb-10 sm:text-3xl">
        What Others are Saying about Dinma
      </div>

      <div className="w-full max-w-[1440px] px-4 md:px-16 lg:px-32 overflow-x-auto noscroll">
        <div className="w-fit flex items-center justify-center overflow-x-auto noscroll gap-3">
          {[{
          // location: "Alpharetta, GA",
          // source: "on finding her happy place",
          source: "Alpharetta, GA",
          testimony: "Being able to choose my provider and see their credentials gave me confidence. Dinma has created a trusted platform for receiving personalized care",
          user: "Rose"
        }, {
          // location: "John's Creek, GA",
          // source: "on meditation’s positive effect on family life",
          source: "John's Creek, GA",
          testimony: "I was hesitant about home visits at first, but Dinma’s professionalism and attention to detail quickly changed my mind. It’s a truly modern way to access healthcare",
          user: "Rachel"
        }, {
          // location: "Alpharetta, GA",
          // source: "on what he learned when sitting with himself",
          source: "Alpharetta, GA",
          testimony: "Dinma made healthcare easy for my family. Scheduling a doctor’s visit to our home was seamless, and the quality of care exceeded our expectations",
          user: "Peter"
        }, {
          // location: "Marietta, GA",
          // source: "on finding her happy place",
          source: "Marietta, GA",
          testimony: "As someone with a busy schedule, I love how Dinma fits into my life. No more waiting rooms or rushing to appointments. Healthcare is now as simple as a few clicks",
          user: "Keri"
        }, {
          // location: "Rosewell, GA",
          // source: "on using meditation",
          source: "Rosewell, GA",
          testimony: "Dinma’s membership options are a lifesaver. I love having a plan that works for my budget while ensuring my family gets quality care when we need it",
          user: "David"
        }].map((item, index) => {
          return <TestimonialCard key={index} location={item.location} source={item.source} testimony={item.testimony} user={item.user} />;
        })}
        </div>
      </div>

      <br />
      <h3 className="text-3xl sm:w-full w-2/3 font-semibold text-center text-new2-20">
        Frequently asked questions
      </h3>
      <p className="text-xl font-normal mb-16 text-center text-[#808080] px-5 sm:px-0">
        Everything you need to know about using Dinma as a patient.
      </p>
      <div className="w-full max-w-[1920px] px-6 md:px-16 lg:px-32">
        <GeneralFAQAccordion data-sentry-element="GeneralFAQAccordion" data-sentry-source-file="MoreSection2.jsx" />
      </div>

      <div className="w-full bg-hero-blur flex flex-col items-center justify-center sm:p-28 p-4 py-20 sm:h-[700px] gap-5">
        <div className="w-full max-w-[1440px] px-4 py-8 sm:py-14 sm:px-20 rounded-3xl flex flex-col items-center justify-center bg-white gap-2 text-black">
          <h2 className="font-medium text-xl sm:text-3xl w-2/3 sm:w-full text-center">
            Urgent Care in Popular Cities
          </h2>
          <p className=" text-sm sm:text-lg text-[#808080] text-center">
            Browse top-rated providers in these areas book online today with
            Dinma.
          </p>
          <br />
          <div className="w-full sm:w-3/4 sm:mt-5 flex items-center justify-start gap-10 sm:gap-20 text-sm sm:text-lg text-[#808080] overflow-x-auto whitespace-nowrap noscroll">
            <span className="text-center flex-shrink-0">Alpharetta, GA</span>
            <span className="text-center flex-shrink-0">Johns Creek, GA</span>
            <span className="text-center flex-shrink-0">Roswell, GA</span>
            <span className="text-center flex-shrink-0">Marietta, GA</span>
          </div>
        </div>

        <div className="w-full max-w-[1440px] px-4 py-8 sm:py-20 sm:px-20 rounded-3xl flex flex-col items-center justify-center bg-black gap-2 text-white">
          <h2 className="font-medium text-xl sm:text-3xl">
            Get Started With Dinma
          </h2>
          <p className="font-medium text-sm sm:text-lg text-[#808080] text-center mb-2">
            Your health journey starts here. Join Dinma and experience
            <br /> healthcare designed for the modern world.
          </p>
          <button type="button" onClick={() => router.push("/patient/membership")} className="bg-new-10 text-white rounded-3xl h-12 sm:h-14 w-fit px-5">
            Join Dinma
            <CaretRightFilled className="text-white ml-5" data-sentry-element="CaretRightFilled" data-sentry-source-file="MoreSection2.jsx" />
          </button>
        </div>
      </div>
    </section>;
};
export default MoreSection2;