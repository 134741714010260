const FloatingPurpleCard = ({
  title,
  subtitle,
  className
}) => {
  return <div className={`w-40 sm:w-80 bg-new2-40 rounded-xl sm:rounded-3xl text-white flex flex-col items-start justify-start sm:gap-2 gap-1 sm:p-4 p-2 ${className}`} data-sentry-component="FloatingPurpleCard" data-sentry-source-file="FloatingPurpleCard.jsx">
      <span className="font-semibold sm:text-2xl text-[10px]">{title}</span>
      <span className="sm:text-base sm:font-normal font-light text-[6.8px]">
        {subtitle}
      </span>
    </div>;
};
export default FloatingPurpleCard;