import Image from "next/image";
const TestimonialCard = ({
  testimony,
  user,
  location,
  source
}) => {
  return <div className="flex flex-col items-start justify-start bg-new2-50 rounded-xl w-72 h-96 p-10 gap-3" data-sentry-component="TestimonialCard" data-sentry-source-file="TestimonialCard.jsx">
      <Image src={"/quotmark.svg"} alt="testimonial" className="object-cover object-center" width={50} height={50} data-sentry-element="Image" data-sentry-source-file="TestimonialCard.jsx" />
      <p className="h-56">{testimony}</p>

      <p className="font-medium text-xs mt-2">
        {user}, {location}
      </p>

      <p className="font-medium text-[10px]">{source}</p>
    </div>;
};
export default TestimonialCard;